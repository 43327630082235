import React from 'react';


const LoadingWheel = (props) => {
  return <img src="MapLoadingWheel.svg" style={props.style} alt=""></img>
}

LoadingWheel.defaultProps = {
  style: {},
}


export default LoadingWheel