import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const styles = {

  modal: {
    minWidth: '50%',
  }
}

export default class ModalShell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }



  // handleSubmitData = (e) => {
  //   e.preventDefault();

  //   this.props.setTaxProcessing(true)
  //   this.props.setTaxError("")
  //   this.props.setTaxHTML("")

  //   let payload = {
  //     zip_code: this.props.zipCode
  //   }

  //   console.log("sending", payload)
  //   // axios.post("http://localhost:5000/api/v1/get_Tax", payload, { withCredentials: true })
  //   axios.post("https://jcat.bike:4001/api/v1/get_tax_rate", payload, { withCredentials: true })
  //     .then(response => {
  //       if (response.status == 200) {
  //         console.log("success")
  //         console.log(response)
  //         this.props.setTaxRate(response.data)
  //       } else {
  //         console.log("failure")
  //         console.log(response)
  //         this.props.setTaxError(`Unable to get tax info, server returned ${response.status}, contact jcat.bike to place an order.`)
  //       }
  //       this.props.setTaxProcessing(false)
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       this.props.setTaxProcessing(false)
  //       this.props.setTaxError("Unable to reach Tax server.")
  //     });
  // }


  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return (
      <>
      {/* <Button color="link" onClick={this.toggle} style={styles.button}>{this.props.modalButtonText}</Button> */}
        <span onClick={this.toggle}> {this.props.modalButton} </span>
      <Modal style={styles.modal} isOpen={this.state.modal} toggle={this.toggle} color="bg-dark">
        <ModalHeader toggle={this.toggle}>{this.props.headerText}</ModalHeader>
        <ModalBody>
         {this.props.modalBody}

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggle}>Close</Button>
          {/* <Button color="secondary" onClick={this.toggle}>Close</Button> */}
        </ModalFooter>
      </Modal>
      </>
    );
  }
}