import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import { throwStatement } from '@babel/types';
import LoadingWheel from './LoadingWheel'

// import StripeCheckout from 'react-stripe-checkout';

const styles = {
  AddressFormStyle: {
    marginLeft: '20px',
    marginRight: '20px',
    // marginTop: '20px',
    marginBottom: '40px',
    color: 'white',
    minHeight: '220px'
  },
  StripeButtonStyle: {
    // marginLeft: '20px',
    // marginBottom: '50px',
  },
  TableStyle: {
    color: 'white',
    // marginLeft: '20px',
    // minHeight:
    // paddingBottom: '280px',
    width: '100%'

  }
}

const TAX_ERROR = "Invalid Zip"
const BIKE_PRICE = 69900
const SHIPPING_COST =8900
// const PAYMENT_SERVER_URL = "http://localhost:4001/api/v1/payment" // test
const PAYMENT_SERVER_URL = "https://jcat.bike:4001/api/v1/payment"
// const TAX_SERVER_URL = "http://localhost:4001/api/v1/get_tax_rate" // test
const TAX_SERVER_URL = "https://jcat.bike:4001/api/v1/get_tax_rate"
const PAYMENT_FAILED_MSG = 'Payment failed, email ride@jcat.bike and we can email you an invoice.'

export default class StripeOrderSection extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      shippingCost: SHIPPING_COST,
      zipCode: '',
      taxRate: null,
      taxRateLoading: false,
    }
    this.setTaxRate()
  }

  setTaxRate = () => {
    if (this.state.zipCode.length == 5) {
      console.log("attempting get tax info")
      this.setState({taxRateLoading: true})
      let that = this
      axios({
        method: "post",
        url: TAX_SERVER_URL,
        timeout: 10 * 1000, // 10 seconds
        data: {
          zip_code: this.state.zipCode,
        },
      })
      .then(function (response) {
        console.log(response.data['totalRate'])
        that.setState({ taxRate: response.data['totalRate'] })
        that.setState({ taxRateLoading: false })
      })
      .catch(function (error) {
        alert('Sorry! Unable to pull tax information, contact ride@jcat.bike to place an order or with any questions.')
        console.log("tax error:", error)
        that.setState({ taxRateLoading: false })
      });
    } 
    // else do nothing
  }

  setZipCodeAndTaxRate = (zip) => {
    this.setState({ zipCode: zip}, this.setTaxRate)
  }

  handleZipCode = (e) => {
    e.preventDefault()
    this.setState({ taxRate: null })
    this.setZipCodeAndTaxRate(e.target.value)
  }

  handleShippingChange = (e) => {
    this.setState({ taxRate: null })

    // console.log(e.target.value)
    this.setState({ shippingCost: e.target.value == "shipping" ? SHIPPING_COST : 0 })

    if (e.target.value == "pickup") {
      this.setZipCodeAndTaxRate("98117")
    } else {
      this.setZipCodeAndTaxRate("")
    }
  }


  onToken = (price) => (token, args) => {
    console.log("attempting payment")
    // console.log(token)
    // console.log(args)

    axios({
      method: "post",
      url: PAYMENT_SERVER_URL,
      timeout: 10 * 1000, // 10 seconds
      data: {
        customer_email: token.email,
        source: token.id,
        metadata: args,
        tax_zip: this.state.zipCode,
        total_price: price,
      }
    }).then(response => {
      if (response.status == 200) {
        console.log("success")
        // console.log(response)
        window.open(response.data['receipt_url'])
        alert('Payment success, you should recieve an email receipt. Email ride@jcat.bike with any questions.')
      // } else if (response.status == 403) {
      //   alert(PAYMENT_FAILED_MSG + 'Does entered zip code match shipping address?')
      //   console.log("failure")
      //   console.log(response)
      } else {
        alert(PAYMENT_FAILED_MSG)
        console.log(response)
      }
    }).catch(error => {
      console.log("unsuccessful payment")
      alert(PAYMENT_FAILED_MSG + '\nDoes entered zip code match shipping address?')
      console.log(error);
      // this.props.setMapProcessing(false)
      // this.props.setMapError("Unable to reach map server.")
    });
  }
  // checkoutSubmit = () => {
  //   axios.post(
  // }

  getTotalPrice = () => {
    return Math.floor(BIKE_PRICE + this.getTaxAmount() + this.state.shippingCost)
  }

  getTaxAmount = () => {
    return Math.floor(BIKE_PRICE * this.state.taxRate)
  }

  render() {
    return (
      <>
      <div className="container">
        <div className="row">
          <div className="col">
            <Form style={styles.AddressFormStyle}>
              <FormGroup tag="fieldset" onChange={this.handleShippingChange}>
                {/* <legend>Fulfillment options</legend> */}
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" value="shipping" defaultChecked />{' '}
                    $89 Flat Rate Shipping to lower 48 states.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" value="pickup" />{' '}
                    Local Pickup (Ballard/Greenwood in Seattle, WA)
                  </Label>
                </FormGroup>
              </FormGroup>

              {
                this.state.shippingCost != 0 ? 
                  <FormGroup>
                    <Col md={8}>
                      <Label for="exampleZip">US Zip Code (must match shipping address) </Label>
                        <Input type="number" maxLength="5" name="zip" id="exampleZip" onChange={this.handleZipCode} />
                        { this.state.zipCode.length > 0 && this.state.taxRate == null ? 
                        <span style={{color:"red"}}> Zip error </span>: ""}
                    </Col>
                  </FormGroup> : ""
              }
            </Form>
          </div>
          <div className="col">
            <table style={styles.TableStyle} className="table table-bordered table-striped table-row">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                  <tr key={'bike'}>
                    <td>Micro Folding Bike</td>
                    <td>${BIKE_PRICE / 100}</td>
                  </tr>
                  { this.state.taxRate != null ? 
                    <>
                      <tr key={'tax'}>
                        <td>Tax</td>
                        <td>${this.getTaxAmount() / 100}</td>
                      </tr> 
                    </>: ""}
                  <tr key={'shipping'}>
                    <td>Shipping</td>
                    <td>${this.state.shippingCost / 100}</td>
                  </tr>
                  {this.state.taxRate != null ?
                    <>
                      <tr key={'total'}>
                        <td><b>Total</b></td>
                        <td><b>${this.getTotalPrice() / 100}</b></td>
                      </tr>
                    </> : ""}
              </tbody>
            </table>
          </div>
        </div>
        
        <div className="row col-lg-12 text-left">
            {/* this.state.taxRate */}
          {/* <StripeCheckout style={styles.StripeButtonStyle}
            amount={this.getTotalPrice()}
            billingAddress
            shippingAddress={this.state.shippingCost != 0}
            description="Folding bike"
            image="https://jcat.bike/img/micro0.jpg"
            locale="auto"
            name="jcat.bike/micro"
            stripeKey="pk_live_r3eGy14m4i6KKuZR520efjQG00m0mnhfKt"
            token={this.onToken(this.getTotalPrice())}
            zipCode
            disabled={this.state.taxRate == null}
          /> */}
          {this.state.taxRateLoading ?
            <LoadingWheel style={{ width: '120px', height: '120px' }} /> : ""}
        </div>
      </div>

      </>


        // {/* {this.state.shippingCost != 0 ? <div><FormGroup>
        //   <Label for="exampleAddress">Address</Label>
        //   <Input type="text" name="address" id="exampleAddress" placeholder="1234 Main St" />
        //   </FormGroup>
        //   <FormGroup>
        //     <Label for="exampleAddress2">Address Line 2</Label>
        //     <Input type="text" name="address2" id="exampleAddress2" placeholder="Apartment, studio, or floor" />
        //   </FormGroup>
        //   <Row form>
        //     <Col md={6}>
        //       <FormGroup>
        //         <Label for="exampleCity">City</Label>
        //         <Input type="text" name="city" id="exampleCity" />
        //       </FormGroup>
        //     </Col>
        //     <Col md={4}>
        //       <FormGroup>
        //         <Label for="exampleState">State</Label>
        //         <Input type="text" name="state" id="exampleState" />
        //       </FormGroup>
        //     </Col>
        //     <Col md={2}>
        //       <FormGroup>
        //         <Label for="exampleZip">Zip Code</Label>
        //         <Input type="text" name="zip" id="exampleZip" />
        //       </FormGroup>
        //     </Col>
        //   </Row></div> : ""} */}

        // {/* <Button>Checkout</Button> */}
    );
  }
}