import React from 'react';
import MyCarousel from './components/MyCarousel';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './components/CheckoutForm';
import StripeOrderSection from './components/StripeOrderSection';
import ModalShell from './components/ModalShell';
import LoadingWheel from './components/LoadingWheel';


const DIN_font = 'Barlow'

const catPicStyle = {
  marginLeft: '-11px',
  marginBottom: '16px',
  width: '150px'
}

const descriptionHeadingStyle = {
  color: '#FFD600',
  fontFamily: DIN_font,
  fontSize: '76px',
  marginTop: '50px',
  fontWeight: 'bold',
}

const foldingVideoModalButtonStyle = {
  fontFamily: 'Helvetica',
  fontSize: '16px',
  color: "#007bff",
  cursor: 'pointer'
}

const warrantyModalButtonStyle = {
  ...foldingVideoModalButtonStyle,
  fontSize: '14px',
  // marginTop: '4px',
  // marginBottom: '4px',

}

const bikeDescriptionStyle = {
  fontFamily: 'Helvetica',
  fontSize: '16px',
  backgroundColor: '#EEEEEE',
}

const bikeDescriptionHeadingStyle = {
  fontFamily: DIN_font,
  fontSize: '24px',
  fontWeight: 'bold',
  // backgroundColor: '#EEEEEE',
  marginBottom: "15px",
  marginTop: "30px",
  marginLeft: "0px",
}


const sectionHeadingStyle = {
  ...bikeDescriptionHeadingStyle,
  
  fontFamily: DIN_font,
  fontWeight: 'bold',
  marginTop: '24px',
  fontSize: '28px',
  marginBottom: '8px',
  // fontWeight: 'bold',
  // fontSize: '24px'
}

const contactUsSubheadingStyle = {
  ...sectionHeadingStyle,
  marginTop: '12px',
  fontSize: '28px',
  marginBottom: '0px',
}

// const descriptionSubHeadingStyle = {
//   color: "grey",
//   fontSize: '14px',
//   marginBottom: '50px',
// }

const boldFont = {
  // fontFamily: DIN_font,
  fontWeight: 'bold',
  // fontSize: '18px',
}

const BikeDescription = () => {


  return(
  <section id="micro" style={bikeDescriptionStyle}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center" style={{ marginTop: '10x', marginBottom: '0px'}}>
            {/* <div style={descriptionSubHeadingStyle}> More pictures coming early September 2019 with product launch. </div> */}
          <h2 style={descriptionHeadingStyle}> /micro</h2>
          
        </div>
        <div className="col-lg-11 text-left">
          <div style={bikeDescriptionHeadingStyle}>
            Imagine having a bike that you could store anywhere.
          </div>
          You could:
          <ul>
            {/* <br/> */}
            
            <li>
              Bike to the bus or train stop without worrying about if there's open space for bikes.
            </li>
            <li>
              Drive and not worry about where to park, by just parking a few blocks away and biking the remaining distance.
            </li>
            <li>
              Easily take your bike on the plane for use at your destination.
            </li>
            <li>
              Take your bike inside nearly anywhere, avoiding theft risk associated with locking up outside.
            </li>
          </ul>

          <div style={bikeDescriptionHeadingStyle}>
           The Micro folding bike was designed to do exactly that.
          </div>
            At a folded size of only 12” x 24” x 24” and a lightweight 27.8 lbs, the Micro folding bike is easily carried and stowed anywhere, with folding and unfolding taking 
              <ModalShell modalBody={
              <video controls muted style={{ width: '100%' }}>
                <source src='../img/unfolding2.mp4' />
              </video>
            }
                modalButton={<span style={foldingVideoModalButtonStyle
                }>less than 15 seconds.</span>}  
                headerText="Unfolding Micro" /> Keep one (or two) in the trunk of your car when you need a bike.
          Want to save space in your apartment? Store it under your bed. The Micro folding bike goes anywhere.
          <div style={bikeDescriptionHeadingStyle}>
            Many sizes of people, one size of bike.
          </div>
          The Micro folding bike does not have the traditional frame constraints of a normal bike, offering nearly a foot of handlebar vertical and horizontal adjustments, and nearly 2 feet of seat post adjustment room, the Micro bike fits anyone between 4' to 6'2" tall unlike a traditional bike with only a 4” range of comfortable rider height.
          <div style={bikeDescriptionHeadingStyle}>
            Commuter ready.
          </div>
          The Micro folding bike comes pre-installed with a rack and fenders, and features a reliable and weatherproof 3 speed Sturmey Archer internally geared hub with wide 33% steps in gearing, enough to conquer most any hills while still keeping up with other cycling traffic.
          The Micro also has 16” wheels, which are lighter and stronger than their larger counterparts.

          {/* The /micro folding bike was designed for both short and long distance hybrid bus/train/car/bike commutes, weekend rides, and light duty bicycle touring.
          <br/>
          <br/>
          To fulfill these roles, the bike needed to easily fit at a rider's feet on public transportation, stable and strong enough to haul gear for commuting or bicycle touring, while still being zippy and fun to ride.
          <br/>
          <br/>
          We're quite happy with the results, and for these purposes, we've never ridden a better bike.
          <br/>
            <div style={sectionHeadingStyle}>Features:</div>
          <ul>
            <li> 
              Geared by a reliable and reputable Sturmey Archer S-RF3 internally geared hub with a wide 177% range with 33% steps between gears (middle gear ratio of 1:1), enough to conquer most hills while still keeping up with other cycling traffic.
            </li>
            <li> 
              Smaller wheels are stronger and lighter, and with 36 spokes these wheels are near “bombproof”.
            </li>
            <li> 
              Fully adjustable seat height AND handlebar height, allowing one bike to fit anyone from 4'8" to 6' (142-183cm) without compromise. This is not possible with a traditional bike due to frame dimension constraints. This and the combination of a durable frame and wheels make the micro a perfect bike for guests.
            </li>
            <li>
              A pre-installed rack, and fenders for our Seattle rain. The rack is capable of holding up to two panniers (with a tent/sleeping bag on top!) with small roller wheels to allow the bike to be easily rolled when folded.
            </li>
          </ul>
          Available locally in the Greenwood neighborhood of Seattle for $699 + tax.
          If you have any questions or are interested in setting up a riding appointment, contact us <a className="js-scroll-trigger" href="#contact">below.</a> */}
          <div style={sectionHeadingStyle}>Technical details: </div>
          <ul>
              <li> <span style={boldFont}>Weight:</span> 27.8lbs (12.6kg) </li>
              <li> <span style={boldFont}>Frame material:</span> Aluminum </li>
              <li> <span style={boldFont}>Internally geared hub:</span> 177% range 3 speed Sturmey Archer S-RF3 </li>
              <li> <span style={boldFont}>Stock tires:</span> Cheng Shin C1698 16x1 3/8”  </li>
              <li> <span style={boldFont}>Rim:</span> 16"x1.50x13Gx36H, alloy. </li>
              <li> <span style={boldFont}>Brakes:</span> Caliper </li>
              <li> <span style={boldFont}>Pedals:</span> Folding </li>
          </ul>
          <div style={{...sectionHeadingStyle, fontSize: '20px'}}>
              Available locally in Seattle or by online orders for<span style={{color: 'red', marginLeft: '5px'}}>$699</span> + tax.
          </div>
          <br/>
        </div>
      </div>
    </div>
  </section>
  )
}

const aboutUsProfileStyle = {
  height: '225px',
  width: '225px',
  border: "7px solid #fff",

}

const teamMemberStyle = {
  textAlign: "center",

}

const aboutUsHeadingStyle = {
  marginTop: "44px",
  // marginBottom: "30px",
  fontSize: "48px",
  fontFamily: DIN_font,
  fontWeight: 'bold',

}

const AboutUs = () => {
  return(
    <section id="About" style={{ backgroundColor:"#F5F4EF"}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading" style={aboutUsHeadingStyle}>About Us</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
            <div style={teamMemberStyle} className="team-member">
              <img style={aboutUsProfileStyle} className="mx-auto rounded-circle" src="../img/ian_icon13.jpg" alt=""/>
            <h4>Ian Hammerstrom</h4>
          </div>
        </div>
        <div className="col-sm-6">
          <div style={teamMemberStyle} className="team-member">
            <img style={aboutUsProfileStyle} className="mx-auto rounded-circle" src="../img/jack_icon.jpg" alt=""/>
            <h4>Jack Hamilton</h4>
          </div>
        </div>
        <div className="col-lg-8 mx-auto text-left">
          <p className="large text-muted" style={{marginLeft: "30px", marginTop:"30px" }}>
            jcat.bike was founded by Ian and Jack, two friends from Seattle, to bring folding bikes into mainstream US cycling and commuting culture.
          </p>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </section>
  )
}

const contactUsStyle = {
  // backgroundImage: 'url("../img/seattle3.jpg")',
  backgroundSize: 'cover',
  backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.3) 100%),url("../img/seattle3.jpg")',
  minHeight: '850px'
}

const ContactUs = () => {
  return (
    <section id="Interested?" style={contactUsStyle }>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading" style={{ ...aboutUsHeadingStyle, color:'#FFD900'}}>Interested?</h2>
            <br/>
          </div>
          <div className="col-lg-12 text-left" style={{ color: "white"} }>
            <div style={contactUsSubheadingStyle}>
              In-person (Seattle)
            </div>
            Call or text Ian of jcat.bike at 206.658.3172 or email <a style={{ color: '#FFD900' }} href="mailto: ride@jcat.bike"> ride@jcat.bike</a> with any questions, to schedule an appointment for a test ride in the Northgate neighborhood of Seattle, or to make an online order. Venmo, Paypal, or Cash accepted.
            <div style={contactUsSubheadingStyle}>
              Online
            </div>
            Fill out the below form to have a Micro folding bike delivered to your door within 5-8 business days.
            {/* On-site online ordering coming soon, for now email <a style={{ color: '#FFD900' }} href="mailto: ride@jcat.bike"> ride@jcat.bike</a> to place an order. */}
            <br/>
            Shipping is $89 flat rate to lower 48 states. (Note: Bikes shipped will require some minor assembly and adjustment upon arrival)
            <br/>
            <br/>
              {/* Currently, jcat.bike does not have a traditional brick & mortar location.
            <br/>
              Bikes are shown by appointment only in the Greenwood neighborhood of Seattle by contacting through one of the following options:
            <br/>
            <br/>
            <ul>
              <li>Email: <a style={{ color: '#FFD900'}} href="mailto: ride@jcat.bike"> ride@jcat.bike</a> </li>
              <li>Call/text normally or through Signal/WhatsApp: +1.206.658.3172</li>
            </ul>
            <br />
            <br /> */}
        </div>
        
          <StripeOrderSection/>

          <br/>
          {/* <div style={{color: 'white', marginBottom: '24px', marginTop: '16px', marginLeft:}}> */}
          <div className="col-lg-12 text-left" style={{ color: "white", marginBottom: '24px', marginTop: '32px' }}>
            {/* We also we offer $100 off each additional bike purchased. */}
            <br/>
            Want to buy a larger quantity or sell our bikes in your city? Talk to us.
          </div>
        </div>

      </div>
    </section>
  )
}

const footerStyle = {
  // marginTop: "50px",
  // marginBottom: "50px",
  // // textAlign: "center",
  // height: '48px',
  backgroundColor: '#DEDEDE',
  backgroundSize: 'cover',
  // justifyContent: "center",
  fontSize: "12px",
  width: '100%'
}

// const containerCopyrightStyle = {
//   textAlign: "center",
//   // top: "50%",
//   // bottom: "50%",
//   // top: "50px",
//   height: "100%",
//   fontSize: "12px",
// }

// const containerRowStyle = {

// }


const Footer = () => {
  return(
    <section id="footer" style={footerStyle}>
      <div className="containercontainer h-100">
        <div className="row h-100 justify-content-center align-items-center" >
          Copyright &copy; jcat.bike LLC 2019
          <span style={{ marginLeft: '15px' }}>
            <ModalShell  modalBody={
              <>
                2 week return policy.
            <br />
                <br />
                1 year parts warranty against manufacturer defects.
            <br />
                Replacements/repairs done by jcat.bike or party authorized by jcat.bike for functionally equivalent part.
            <br />
                Customer is responsible for transporting bicycle to jcat.bike representative for both returns and repairs.
            </>
            }
              modalButton={<span style={warrantyModalButtonStyle}> Warranty/Returns Policy </span>}
              headerText="Warranty/Returns Policy" />
          </span>

        </div >

      </div >
    </section>
  )
}

const CatFooter = () => {
  return(
    // <div class="container">
    //   <div class="row">
    //     <div class="col">
    //       <img style={catPicStyle} src="../img/cat2.gif" alt="" />
    //     </div>
    //     <div class="col">
    //       <img style={catPicStyle} src="../img/cat2.gif" alt="" />
    //     </div>
    //   </div>
    // </div>
    <img style={catPicStyle} src="../img/cat.gif" alt="" />
  )
}

// const StripeProviderWrapper = () => {
//   return (
//     // <StripeProvider apiKey="pk_test_xkbia5SdLYOdJHr7xdv3PSHX00WvD9VIAx">
//     //   <div className="example">
//     //     <h1>React Stripe Elements Example</h1>
//     //     <Elements>
//     //       <CheckoutForm />
//     //     </Elements>
//     //   </div>
//     // </StripeProvider>
//     // <CheckoutForm/>

//     <AddressForm />
//   )

// }

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true }
  }

  componentDidMount() {
    this.setState({isLoading: false})
  }

  render(){
    return (
      !this.state.isLoading ?
      <>
      <MyCarousel />
      <BikeDescription/>
      <ContactUs />
      <AboutUs/>
      <Footer/>
      <CatFooter/>
      </> : <LoadingWheel style={{ width: '100%'}} />
    );
  }
}

// <li>{user.id}: {user.username} <img src={user.profileImage} alt="" style={profileImgStyle} ></img></li>

// render(){
//   return (
//     <div>
//       <h1>Home Page</h1>
//       <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
//         <div className="carousel-inner"></div>
//           {
//             this.state.users.map(user =>
//               <div className="carousel-item">
//                 <img src={user.profileImage} alt=""></img>
//                 <div className="carousel-caption d-none d-md-block">
//                   <h5> {user.id} </h5>
//                   <p> {user.username} </p>
//                 </div>
//               </div>
//             )
//           }
//         <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
//           <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//           <span className="sr-only">Previous</span>
//         </a>
//         <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
//           <span className="carousel-control-next-icon" aria-hidden="true"></span>
//           <span className="sr-only">Next</span>
//         </a>
//       </div>
//     </div>
//   )
// }
// }

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

